import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    newStartDate: null,
    newEndDate: null,
};


export const setNewStartDate = (newStartDate) => {
    return {
        type: 'jobIdData/setNewStartDate',
        payload: newStartDate
    };
}

export const setNewEndDate = (newEndDate) => {
    return {
        type: 'jobIdData/setNewEndDate',
        payload: newEndDate
    };
};

// Create a slice to manage the state
const newDatesSlice = createSlice({
    name: 'jobIdData',
    initialState,
    reducers: {
       
        // Reducer to handle setting the new start date
        setNewStartDate: (state, action) => {
            state.newStartDate = action.payload;
        },
        // Reducer to handle setting the new end date
        setNewEndDate: (state, action) => {
            state.newEndDate = action.payload;
        },
    },
});

// Export the reducer
export default newDatesSlice.reducer;