import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Define the initial state for your slice
const initialState = {
  loading: false,
  errorState: false,
  errorMsg: "",
  honeywelldata: null,
  isCarouselOpen: false,
  carouselMediaDetails: {
    visible: false,
    mediaType: "",
    status: "",
    zoneName: "",
  },
  carouselData: [],
  isMultimediaCarouselOpen: false,
  multimediaCarouselData: [],
  multiCarouselMediaDetailsArray: [],
  isEmailPopupOpen: false,
  pdfURL: "",
  pdfSelected: "",
  emailSubject: "",
  emailTo: "",
  emailCC: "",
  emailBCC: "",
  incidentFormOpen: false,
  incidentFormData: [],
  addIncidentFormOpen: false,
  refreshIncidentTable: false,
  referrer: null,
  incidentsReportedCount: 0,
  incidentsOpenCount: 0,
  chartData: null,
  evidenceReadingStatus: false,
  evidenceReadingDetails: null
};

export const fetchGenericData = createAsyncThunk(
  "roomDataSlice/fetchGenericData",
  async ({
    authenticationToken,
    newstartDate,
    newendDate,
    accountID,
    referrer,
  }) => {
    try {
      const response = await fetch(`https://${referrer}getAnalyticsData`, {
        // const response = await fetch("https://analytics.telepresenz.com/analytics/getAnalyticsData", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authenticationToken}`,
        },
        body: JSON.stringify({
          account_id: accountID,
          start_date: newstartDate,
          end_date: newendDate,
        }),
      });

      // if (!response.ok) {
      //   throw new Error('Network response was not ok');
      // }
      return response.json();
    } catch (error) {
      throw new Error("Error fetching analytics data: " + error.message);
    }
  }
);


export const editManualEntryData = createAsyncThunk(
  "roomDataSlice/editManualEntryData",
  async ({ authenticationToken, referrer, task_details }) => {
    try {
      const response = await fetch(`https://${referrer}/editManualEntryData`, { // Ensure there's a '/' before editManualEntryData
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authenticationToken}`,
        },
        body: JSON.stringify({
          task_details, // Use task_details directly
        }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      return response.json();
    } catch (error) {
      throw new Error("Error editing manual entry data: " + error.message);
    }
  }
);

export const setCarouselValue = (carouselDataValue) => {
  return {
    type: "genericData/setCarouselValue",
    payload: carouselDataValue,
  };
};

export const setMultimediaCarouselValue = (value) => {
  return {
    type: "genericData/setmultimediaCarouselValue",
    payload: value,
  };
};

export const setCarouselStatus = (carouselStatusValue) => {
  return {
    type: "genericData/setCarouselStatus",
    payload: carouselStatusValue,
  };
};

export const setCarouselMediaDetails = (value) => {
  return {
    type: "genericData/setCarouselMediaDetails",
    payload: value,
  };
};

export const setMultimediaCarouselMediaDetails = (value) => {
  return {
    type: "genericData/setMultimediaCarouselMediaDetails",
    payload: value
  }
}

export const setEmailPopupStatus = (status) => {
  return {
    type: "genericData/setEmailPopupStatus",
    payload: status
  }
}

export const setPDFName = (url) => {
  return {
    type: "genericData/setPDFName",
    payload: url
  }
}

export const setEmailSubject = (text) => {
  return {
    type: "genericData/setEmailSubject",
    payload: text
  }
}

export const setEmailTo = (value) => {
  return {
    type: "genericData/setEmailTo",
    payload: value
  }
}

export const setEmailCC = (value) => {
  return {
    type: "genericData/setEmailCC",
    payload: value
  }
}

export const setEmailBCC = (value) => {
  return {
    type: "genericData/setEmailBCC",
    payload: value
  }
}

export const setIncidentFormStatus = (status) => {
  return {
    type: "genericData/setIncidentFormStatus",
    payload: status,
  };
};

export const setIncidentFormData = (value) => {
  return {
    type: "genericData/setIncidentFormData",
    payload: value,
  };
};

export const setMultimediaCarouselStatus = (value) => {
  return {
    type: "genericData/setmultimediaCarouselStatus",
    payload: value,
  };
};

export const setAddIncidentFormStatus = (status) => {
  return {
    type: "genericData/setAddIncidentFormStatus",
    payload: status,
  };
};

export const setRefreshIncidentTable = (status) => {
  return {
    type: "genericData/setRefreshIncidentTable",
    payload: status,
  };
};

export const setReferrer = (value) => {
  return {
    type: "genericData/setReferrer",
    payload: value,
  };
};

export const setIncidentCount = (value) => {
  return {
    type: "genericData/setIncidentCount",
    payload: value,
  };
};

export const setChartData = (value) => {
  return {
    type: "genericData/setChartData",
    payload: value,
  };
};

export const setEvidenceReadingDetails = (value) => {
  return {
    type: "genericData/setEvidenceReadingDetails",
    payload: value
  }
}

export const setEvidenceReadingStatus = (value) => {
  return {
    type: "genericData/setEvidenceReadingStatus",
    payload: value
  }
}

// Create a slice to manage the state
const genericDataSlice = createSlice({
  name: "genericData",
  initialState,
  reducers: {
    // Reducer to handle setting the room name
    setCarouselStatus: (state, action) => {
      state.isCarouselOpen = action.payload;
    },
    setCarouselMediaDetails: (state, action) => {
      state.carouselMediaDetails = {
        visible: action.payload.visible,
        mediaType: action.payload.mediaType,
        status: action.payload.status,
        zoneName: action.payload.zoneName,
      };
    },
    setCarouselValue: (state, action) => {
      state.carouselData = action.payload;
    },
    setmultimediaCarouselStatus: (state, action) => {
      state.isMultimediaCarouselOpen = action.payload;
    },
    setmultimediaCarouselValue: (state, action) => {
      state.multimediaCarouselData = action.payload;
    },
    setMultimediaCarouselMediaDetails: (state, action) => {
      state.multiCarouselMediaDetailsArray = action.payload;
    },
    setEmailPopupStatus: (state, action) => {
      state.isEmailPopupOpen = action.payload;
    },
    setPDFName: (state, action) => {
      state.pdfURL = action.payload[0];
      state.pdfSelected = action.payload[1];
    },
    setEmailSubject: (state, action) => {
      state.emailSubject = action.payload;
    },
    //inplace cc this should come in To 10/10/2024 - TZ-1586
    setEmailTo: (state, action) => {
      state.emailTo = action.payload;
    },
    setEmailCC: (state, action) => {
      state.emailCC = action.payload;
    },
    setEmailBCC: (state, action) => {
      state.emailBCC = action.payload;
    },
    setIncidentFormStatus: (state, action) => {
      state.incidentFormOpen = action.payload;
    },
    setIncidentFormData: (state, action) => {
      state.incidentFormData = action.payload;
    },
    setAddIncidentFormStatus: (state, action) => {
      state.addIncidentFormOpen = action.payload;
    },
    setRefreshIncidentTable: (state, action) => {
      state.refreshIncidentTable = action.payload;
    },
    setReferrer: (state, action) => {
      if (action.payload === "192" || action.payload === "onprem") {
        state.referrer = "192.168.0.102/analytics/";
      } else if (action.payload === "dev") {
        state.referrer = "dev.telepresenzdemo.com/analytics/";
      } else if (action.payload === "qa") {
        state.referrer = "qa.telepresenzdemo.com/analytics/";
      } else if (action.payload === "demo") {
        state.referrer = "demo.telepresenz.com/analytics/";
      } else if (action.payload === "app") {
        state.referrer = "app.telepresenz.com/analytics/";
      } else if (action.payload === "honeywell") {
        state.referrer = "analytics.telepresenz.com/analytics/";
      } else if (action.payload === "staging") {
        /** TZ-1452 */
        state.referrer = "staging.telepresenzdemo.com/analytics/";
      } else if (action.payload === "pgcs") {
        state.referrer = "pgcs.telepresenz.com/analytics/";
      } else if (action.payload === "hps") {
        state.referrer = "hps.telepresenz.com/analytics/";
      } else if (action.payload === "fortliberty") {
        state.referrer = "fortliberty.telepresenz.com/analytics/";
      } else {
        state.referrer = "InvalidReferrer";
      }
    },
    setIncidentCount: (state, action) => {
      state.incidentsReportedCount = action.payload.totalCount;
      state.incidentsOpenCount = action.payload.openCount;
    },
    setChartData: (state, action) => {
      state.chartData = action.payload;
    },
    setEvidenceReadingStatus: (state, action) => {
      state.evidenceReadingStatus = action.payload;
    },
    setEvidenceReadingDetails: (state, action) => {
      state.evidenceReadingDetails = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGenericData.pending, (state) => {
        state.loading = true;
        state.errorState = false;
        state.errorMsg = "";
      })
      .addCase(fetchGenericData.fulfilled, (state, action) => {
        state.loading = false;
        state.errorState = false;
        state.errorMsg = "";
        state.honeywelldata = action.payload;
      })
      .addCase(fetchGenericData.rejected, (state, action) => {
        state.loading = false;
        state.errorState = true;
        state.errorMsg = action.error.message;
      })
       .addCase(editManualEntryData.pending, (state) => {
        state.loading = true; 
        state.errorState = false;
        state.errorMsg = "";
      })
      .addCase(editManualEntryData.fulfilled, (state, action) => {
        state.loading = false; 
        state.errorState = false;
        state.errorMsg = "";
        
      })
      .addCase(editManualEntryData.rejected, (state, action) => {
        state.loading = false;
        state.errorState = true;
        state.errorMsg = action.error.message; 
      });
  },
});

export default genericDataSlice.reducer;
