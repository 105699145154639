import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Define the initial state for your slice
const initialState = {
  authenticationToken: null,
  errorState:false,
  tokenError:"",
  accountID:null,
  companyName:null,
  accountHolderName:null,
  loading:false,
  behaviour:null,
};

const behaviourArray = [
  "honeywell", "fda", "pgcs", "fortliberty"
]

const getBehaviourSet = (account_id) => {
  switch (account_id) {
    case "37010200":
    case "37010004":
    case "37010003": return "honeywell";
    case "38010002":
    case "38010001":    
    case "31010039": return "pgcs";    
    case "36010194":
    case "25010002":
    case "38010008": return "fortliberty";
    default: return "default";
  }
}

// Define an async thunk action creator to fetch data from an API
export const fetchData = createAsyncThunk(
  'authenticationDataSlice/fetchData',
  async (receivedParameters) => {
        try {
      const response = await fetch(`https://${receivedParameters.referrerParam}login`, {
      // const response = await fetch('https://analytics.telepresenz.com/analytics/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ data: receivedParameters.dataParam }),
      });

      if (response.status === 400) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Bad request');
      }

      if (!response.ok) {
        // throw new Error('Network response was not ok');
      }

      const data = await response.json();
      return {
        auth_token: data.auth_token,
        account_id: data.account_id,
        company_name:data.company_name,
        accountHolder_name:data.first_name + "" + data.last_name,
        behaviour: data.behaviour
      };
    } catch (error) {
      throw new Error('Error fetching data: ' + error.message);
    }
  }
);

// Create a slice to manage the state
const authenticationDataSlice = createSlice({
  name: 'authenticData',
  initialState,
  reducers: {
    // Reducer to handle setting the room name
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchData.pending, (state) => {
        state.loading = true;
        state.errorState = false;
        state.tokenError = "";
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        state.loading = false;
        state.errorState = false;
        state.tokenError = "";
        state.authenticationToken = action.payload.auth_token;
        state.accountID = action.payload.account_id;
        state.companyName = action.payload.company_name;
        state.accountHolderName = action.payload.accountHolder_name;
        //state.behaviour = (action.payload.account_id === "37010200" || action.payload.account_id === "37010003") ? "honeywell": "default"
        state.behaviour = action.payload.behaviour !== "" && action.payload.behaviour !== null && behaviourArray.includes(action.payload.behaviour) === true  ? action.payload.behaviour : "default";
      })
      .addCase(fetchData.rejected, (state, action) => {
        state.loading = false;
        state.errorState = true;
        state.tokenError = action.error.message;
      })
  },

});

// Export the reducer
export default authenticationDataSlice.reducer;