import { combineReducers } from 'redux';
import themeReducer from "./features/theme/themeSlice";
import dateReducer from "./features/hwUserData/hwUserDataSlice";
import genericDataReducer from "./features/genericReportData/genericReportDataSlice";
import authenticationDataSlice from "./features/authenticationCode/authenticationDataSlice";
import individualRoomData from "./features/individualRoomData/individualRoomDataSlice";
import tabsActiveIndex from "./features/activeTabIndex/activeTabIndex";
import jobIDData from "./features/jobIdForRounds/jobIdForRounds";
import newDates from "./features/dateForJob/dateForJob";
import sliderTabs from "./features/activeTabIndex/tabsVisibility";
import incidentReportEditDataSlice from "./features/IncidentReportUpdate/IncidentReportUpdateDataSlice";
import AddIncidentForm from './features/AddIncidentForm/AddIncidentForm';
import signout from './features/signout/signout';
import incidentAccountReducer from './features/IncidentReportUpdate/IncidentsOfAcc';
import hamiltonGenericDataReducer from './features/hamilton/hamiltonGenericReportDataSlice';
import zoneNameListReducer from './features/hamilton/hamiltonZoneListDataSlice';
import ActionTypeListDataReducer from './features/actionPlan/actionPlanDataSlice';
import TechniciansNamesDataReducer from './features/actionPlan/technicianListSlice';
import AddActionDataReducer from './features/actionPlan/addActionPLanDataSlice';
import ActionPlanJobsDataReducer from './features/actionPlan/actionPlanJobsDataSlice';
import incidentIdReducer from './features/IncidentReportUpdate/SingleIncidentDataSlice';
import emailIncidentReducer from './features/IncidentReportUpdate/EmailIncidentReport';
import afterHourReport from './features/afterHourReport/afterHourReportSlice'; /**TZ-1163 */
import libertyTemplates from './features/libertyTemplates/libertyTemplatesSlice';
import dateMonthSlice from './features/hwUserData/libertyDateSlice';
import opViewReducer from './features/opsViewData/opsViewData';

const rootReducer = combineReducers({
    ThemeOptions: themeReducer,
    dateSelected: dateReducer,
    genericData: genericDataReducer,
    tokenData: authenticationDataSlice,
    singleRoomData: individualRoomData,
    activeIndexData: tabsActiveIndex,
    selectedJobId: jobIDData,
    selectedNewDates: newDates,
    tabsVisibilityData: sliderTabs,
    incidentReportEditData: incidentReportEditDataSlice,
    addIncident: AddIncidentForm,
    signoutData: signout,
    incidentAccountData: incidentAccountReducer,
    hamiltonGenericData: hamiltonGenericDataReducer,
    zoneListData: zoneNameListReducer,
    actionTypeList: ActionTypeListDataReducer,
    allTechniciansNames: TechniciansNamesDataReducer,
    addActionPlan: AddActionDataReducer,
    actionPlanJobsData: ActionPlanJobsDataReducer,
    incidentIdData: incidentIdReducer,
    emailIncidentData: emailIncidentReducer,
    afterHourReport: afterHourReport,
    libertyTemplates: libertyTemplates,
    dateMonth: dateMonthSlice,
    opsData: opViewReducer  
});

export default rootReducer;